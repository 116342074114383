<script setup></script>

<template>
  <footer
    class="w-full flex flex-col lg:flex-row justify-center space-x-4 2xl:max-w-[70%] mx-auto p-3"
  >
    <div></div>
    <div class="flex flex-row justify-center space-x-2 items-center">
      <div>
        <img
          :src="require('/assets/images/logo_comect.png')"
          alt="Logo"
          class="w-5 h-5 md:w-5 md:h-5 rounded-full mx-auto"
        />
      </div>
      <p class="text-black text-xs">myDidSign © 2024 Built by COMECT</p>
    </div>
    <div class="flex flex-row justify-center text-xs space-x-4">
      <a
        href="https://mydid.com/legal/terms-us.html"
        target="_blank"
        class="text-blue-400 hover:underline"
        >Terms of Service</a
      >
      <span class="text-gray-400 dark:text-gray-600">|</span>
      <a
        href="https://mydid.com/legal/privacy-us.html"
        target="_blank"
        class="text-blue-400 hover:underline"
        >Privacy</a
      >
      <span class="text-gray-400 dark:text-gray-600">|</span>
      <a
        href="https://mydid.com/legal/cookies-us.html "
        target="_blank"
        class="text-blue-400 hover:underline"
        >Data Policy</a
      >
    </div>
  </footer>
</template>

<style scoped></style>
