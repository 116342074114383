<template>
  <div
    class="border rounded-lg w-full max-w-[600px] 2xl:w-3/4 shadow-md h-full p-2 items-center flex flex-col sm:p-5"
  >
    <VuePdfEmbed
      class="w-full aspect-[3/4] overflow-scroll"
      annotation-layer
      text-layer
      :source="pdfBase64"
    />
    <div
      v-if="pdfName"
      @click="$emit('download')"
      class="flex pt-2 justify-center items-center gap-2 cursor-pointer"
    >
      <p class="text-center text-xs text-black">
        {{ truncatedPdfName }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';

export default {
  name: 'PdfViewer',
  props: {
    pdfBase64: {
      type: String,
      required: true,
    },
    pdfName: {
      type: String,
      required: true,
    },
  },
  emits: ['download'],
  setup(props) {
    const truncatedPdfName = computed(() => {
      return props.pdfName.length > 50
        ? props.pdfName.slice(0, 10) + '...' + props.pdfName.slice(-10)
        : props.pdfName;
    });

    return {
      truncatedPdfName,
    };
  },
  components: { VuePdfEmbed },
};
</script>
