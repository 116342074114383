<template>
  <div
    class="py-5 px-5 lg:px-16 flex flex-col items-center justify-center bg-white"
  >
    <main
      class="container mx-auto px-4 md:py-10 flex flex-col md:flex-row items-start justify-center"
    >
      <div
        class="w-full hidden sm:block md:w-1/2 lg:w-1/3 gap-2 mb-10 md:mb-0 md:mr-10"
      >
        <img
          :src="require('/assets/images/signer_img.png')"
          alt="e-signature illustration"
          class="w-[200px] h-auto md:w-[500px] mx-auto"
        />
      </div>
      <div
        class="w-full md:w-1/2 lg:w-1/2 flex flex-col items-center lg:items-start"
      >
        <!-- Title and Description -->
        <div class="my-5 w-full">
          <div class="flex items-center my-5 gap-2">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-5 sm:w-6 lg:w-8"
            />
            <h2 class="text-xl font-medium text-black">Web3 e-signatures</h2>
          </div>
          <p class="text-lg max-w-xl text-start font-light text-black">
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>
        <div class="flex flex-col gap-4 w-full max-w-md">
          <router-link to="/sign" class="w-full">
            <button
              class="w-full space-x-2 justify-center items-center border border-gray-300 flex-row flex px-6 py-4 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
            >
              <img
                :src="require('/assets/images/File_Check.png')"
                alt="File_Check.png"
                class="w-6"
              />
              <span class="">Sign a document</span>
            </button>
          </router-link>
          <router-link to="/verify" class="w-full">
            <button
              class="w-full space-x-2 justify-center items-center border border-gray-300 flex-row flex px-6 py-4 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
            >
              <img
                :src="require('/assets/images/warning.png')"
                alt="File_Check.png"
                class="w-5"
              />
              <span class="">Verify a document</span>
            </button>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>
