<template>
  <div
    class="py-5 px-5 lg:px-16 flex flex-col items-center justify-center bg-white"
  >
    <div
      v-if="!loading"
      class="container mx-auto px-4 md:py-10 flex flex-col md:flex-row items-start justify-center"
    >
      <!-- Image Section -->
      <div
        class="w-full hidden sm:block md:w-1/2 lg:w-1/3 gap-2 mb-10 md:mb-0 md:mr-10"
      >
        <img
          :src="require('/assets/images/signer_img.png')"
          alt="e-signature illustration"
          class="w-[350px] h-auto md:w-[500px] lg:w-[600px] 2xl:w-[700px] mx-auto"
        />
      </div>
      <!-- Content Section -->
      <div
        class="w-full md:w-1/2 lg:w-1/2 flex flex-col items-center lg:items-start"
      >
        <!-- Title and Description -->
        <div class="my-7 w-full">
          <div class="flex items-center gap-2 my-5">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-5 sm:w-6 lg:w-8"
            />
            <h2 v-if="!alreadySigned" class="text-xl font-medium text-black">
              Web3 e-signatures
            </h2>
            <h2 v-else class="text-xl font-medium text-black">
              Document already signed
            </h2>
          </div>
          <p
            class="text-lg max-w-xl text-start lg:text-start font-light text-black"
          >
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>

        <!-- Upload Section -->
        <div class="flex flex-col gap-4 w-full">
          <div
            v-if="
              (!pdfHash && !pdfBase64 && !originalPdfBase64) || alreadySigned
            "
            class="w-full flex flex-col gap-4 justify-center items-center md:items-start md:w-auto 2xl:w-2/3"
          >
            <div
              v-if="pdfMessage && alreadySigned"
              class="w-full mt-5 flex flex-col justify-start items-start"
            >
              <p class="text-start font-medium text-xl text-[#4131C6] mb-2">
                Your document cannot be signed.
              </p>
              <p class="text-start font-medium text-lg mb-1">Reason:</p>
              <p class="text-start text-lg mb-5">
                <span class="text-xl mx-1 text-[#4131C6]"> • </span
                >{{ pdfMessage }}
              </p>
            </div>
            <button
              @click="$refs.pdfToSignInput.click()"
              :class="alreadySigned ? 'border-red-300' : 'border-gray-300'"
              class="w-full max-w-[412px] space-x-2 justify-center items-center border border-gray-300 flex-row flex px-6 py-4 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
              :disabled="loading"
            >
              <img
                :src="require('/assets/images/add.png')"
                alt="plus"
                class="w-5"
              />
              <span class="">{{
                loading ? 'Loading...' : 'Upload PDF to sign'
              }}</span>
            </button>
            <input
              type="file"
              @change="onPdfInput($event)"
              ref="pdfToSignInput"
              class="hidden"
              accept="application/pdf"
            />
          </div>

          <!-- PDF Uploaded Section -->
          <div v-if="originalPdfBase64" class="w-full">
            <p
              v-if="!alreadySigned"
              class="text-center text-xs text-black mb-4"
            >
              {{
                pdfName.length > 50
                  ? pdfName.slice(0, 10) + '...' + pdfName.slice(-10)
                  : pdfName
              }}
            </p>
            <div v-if="!alreadySigned && !loading" class="space-y-6">
              <!-- Sender Information -->
              <div>
                <p class="font-bold mb-2">Your informations</p>
                <div
                  class="flex flex-col sm:flex-row gap-3 sm:gap-5 w-full mb-3"
                >
                  <input
                    type="text"
                    placeholder="First Name"
                    class="w-full sm:w-1/2 outline-none rounded-md border border-gray-300 invalid:border-red-500 p-2 sm:p-3"
                    v-model="firstName"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="w-full sm:w-1/2 outline-none rounded-md border border-gray-300 invalid:border-red-500 p-2 sm:p-3"
                    v-model="lastName"
                  />
                </div>
                <div
                  class="flex flex-col sm:flex-row gap-3 sm:gap-5 w-full mb-3"
                >
                  <input
                    type="text"
                    placeholder="Email"
                    class="w-full sm:w-1/2 outline-none rounded-md border border-gray-300 invalid:border-red-500 p-2 sm:p-3 mb-3"
                    v-model="email"
                  />
                  <div
                    class="flex items-center gap-2 cursor-pointer mb-3"
                    @click="emailIsASigner = !emailIsASigner"
                  >
                    <input
                      type="checkbox"
                      class="outline-none rounded-md border cursor-pointer border-gray-300"
                      v-model="emailIsASigner"
                    />
                    <div class="w-full">
                      <p class="text-xs">I'm also a signer</p>
                    </div>
                  </div>
                </div>

                <p class="text-xs text-gray-400 mb-2">
                  <i class="fas fa-info-circle mr-1"></i>Your email address will
                  be used to keep you updated when a new person has signed the
                  document. You can also check the box above to be part of the
                  requested signers for this document.
                </p>
              </div>

              <!-- Signers Section -->
              <div>
                <p class="font-bold mb-2">Signers</p>
                <div
                  v-for="(signer, index) in signers"
                  :key="index"
                  class="relative mb-5"
                >
                  <div
                    class="flex flex-col sm:flex-row gap-3 sm:gap-5 w-full mb-3"
                  >
                    <input
                      type="text"
                      :placeholder="`First Name for signer ${index + 1}`"
                      class="w-full sm:w-1/2 outline-none rounded-md border border-gray-300 p-2 sm:p-3"
                      v-model="signer.firstName"
                    />
                    <input
                      type="text"
                      :placeholder="`Last Name for signer ${index + 1}`"
                      class="w-full sm:w-1/2 outline-none rounded-md border border-gray-300 p-2 sm:p-3"
                      v-model="signer.lastName"
                    />
                  </div>
                  <input
                    type="text"
                    :placeholder="`Email for signer ${index + 1}`"
                    class="w-full sm:w-1/2 outline-none rounded-md border border-gray-300 p-2 sm:p-3 mb-3 invalid:border-red-500"
                    v-model="signer.email"
                  />
                  <button
                    @click="removeSigner(index)"
                    class="absolute top-3 right-3 text-xl text-gray-500 cursor-pointer"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                  <p v-if="signer.error" class="text-xs text-red-700 italic">
                    {{ signer.error }}
                  </p>
                </div>
                <button
                  v-if="signers.length < 10"
                  @click="addSigner"
                  class="w-full bg-gray-100 rounded-md border border-gray-300 cursor-pointer p-2 sm:p-3 mb-3"
                >
                  <p class="text-center text-gray-500 font-bold text-sm">
                    <i class="fas fa-plus mr-2"></i>Add signer
                  </p>
                </button>
              </div>

              <button
                :class="[
                  'w-full rounded-md p-2 sm:p-3 mb-3',
                  loading
                    ? 'bg-gray-400 text-gray-700 cursor-wait'
                    : 'bg-primaryGradient text-white cursor-pointer',
                ]"
                @click="createSession"
                :disabled="loading"
              >
                <p
                  class="text-center flex gap-2 justify-center items-center flex-row font-bold text-sm"
                >
                  <img
                    :src="require('/assets/images/send_icon.png')"
                    alt="send"
                    class="w-4 sm:w-5"
                  />
                  <span>{{ loading ? 'Processing...' : 'Send' }}</span>
                </p>
              </button>
              <p
                v-if="errorMessage"
                class="text-xs text-center text-red-700 italic mt-1"
              >
                {{ errorMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="px-4">
      <div class="flex justify-center mb-5">
        <custom-loader color="#D0C4E7" size="50px"></custom-loader>
      </div>
      <p class="text-secondary text-center mb-3">{{ loadingMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { PDFDocument } from 'pdf-lib';
import crypto from 'crypto';
import { createToast } from 'mosha-vue-toastify';

export default {
  setup() {
    const api = inject('api');
    const utils = inject('utils');
    const router = useRouter();
    const pdfFile = ref(null);
    const pdfMessage = ref(null);
    const pdfBase64 = ref(null);
    const originalPdfBase64 = ref(null);
    const pdfHash = ref(null);
    const pdfName = ref(null);
    const pdfDoc = ref(null);
    const pdfViewer = ref(null);
    const loading = ref(false);
    const loadingMessage = ref('Please wait...');
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const signers = ref([
      {
        firstName: '',
        lastName: '',
        email: '',
        error: null,
      },
    ]);
    const emailIsASigner = ref(true);
    const errorMessage = ref(null);
    const alreadySigned = ref(false);

    const formattedSigners = computed(() => {
      return [
        ...signers.value.map((signer) => ({
          firstName: signer.firstName,
          lastName: signer.lastName,
          email: signer.email,
        })),
        ...(emailIsASigner.value
          ? [
              {
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
              },
            ]
          : []),
      ];
    });

    function addSigner() {
      signers.value.push({
        firstName: '',
        lastName: '',
        email: '',
        error: null,
      });
    }

    function removeSigner(index) {
      signers.value.splice(index, 1);
    }

    async function createSession() {
      if (formattedSigners.value.length == 0) {
        errorMessage.value = 'Please add signers';
        return;
      }

      if (
        formattedSigners.value.length !=
        new Set(formattedSigners.value.map((item) => item.email)).size
      ) {
        errorMessage.value = 'Duplicate emails found';
        return;
      }

      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!emailRegex.test(email.value)) {
        errorMessage.value = 'Invalid or missing email';
        return;
      }

      let valid = true;
      signers.value.forEach((signer, index) => {
        if (!emailRegex.test(signer.email)) {
          signer.error = `Invalid or missing email`;
          valid = false;
        } else if (
          !signer.firstName ||
          signer.firstName.length < 2 ||
          !signer.lastName ||
          signer.lastName.length < 2
        ) {
          signer.error = `Invalid or missing firstname or lastname`;
          valid = false;
        } else {
          signer.error = null;
        }
      });

      if (!valid) {
        return;
      }

      if (
        !firstName.value ||
        firstName.value.length < 2 ||
        !lastName.value ||
        lastName.value.length < 2
      ) {
        errorMessage.value = 'Missing or invalid firstname or lastname';
        return;
      }

      if (!pdfBase64.value) {
        errorMessage.value = 'Missing or invalid PDF document';
        return;
      }

      loading.value = true;
      loadingMessage.value = 'Creating session...';
      errorMessage.value = null;

      try {
        utils.resetPdfHeaders(pdfDoc.value);
        const updatedPdfDoc = await utils.addSignaturePage(
          pdfDoc.value,
          formattedSigners.value,
          new Date().toLocaleDateString()
        );

        const pdfBase64Data = await updatedPdfDoc.saveAsBase64();
        pdfBase64.value = 'data:application/pdf;base64,' + pdfBase64Data;
        pdfHash.value = crypto
          .createHash('sha256')
          .update(pdfBase64.value.split('base64,')[1])
          .digest('hex');

        const response = await api.createSession({
          email: email.value,
          firstName: firstName.value,
          lastName: lastName.value,
          signers: formattedSigners.value,
          pdfData: pdfBase64.value,
          originalPdfData: originalPdfBase64.value,
          pdfHash: pdfHash.value,
          pdfName: pdfName.value,
        });

        const sessionId = response.data.id;
        loading.value = false;
        router.push({
          path: '/session/' + sessionId,
          query: { email: email.value },
        });
      } catch (error) {
        loading.value = false;
        errorMessage.value =
          'An error occurred while creating the session. Please try again.';
        console.error('Session creation error:', error);
      }
    }

    async function onPdfInput(event) {
      loading.value = true;
      loadingMessage.value = 'Loading PDF...';
      pdfFile.value = null;
      pdfMessage.value = null;
      if (event.target.files[0]['type'] != 'application/pdf') {
        createToast(`Please select a PDF file`, {
          position: 'bottom-center',
          hideProgressBar: true,
          toastBackgroundColor: 'red',
        });
        loading.value = false;
        return;
      }
      pdfFile.value = event.target.files[0];
      pdfMessage.value = event.target.files[0].name;
      pdfName.value = event.target.files[0].name;

      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          originalPdfBase64.value = e.target.result;
          const pdfData = e.target.result.split('base64,')[1];

          try {
            pdfDoc.value = await PDFDocument.load(pdfData);
          } catch (err) {
            console.log(err);
            originalPdfBase64.value = null;
            pdfFile.value = null;
            pdfMessage.value = null;
            pdfName.value = null;
            createToast(`PDF file is not valid`, {
              position: 'bottom-center',
              hideProgressBar: true,
              toastBackgroundColor: 'red',
            });
            loading.value = false;
            return;
          }

          const metadata = utils.getMetadataFromPdfDoc(pdfDoc.value);
          if (metadata && metadata.verifications.length > 0) {
            alreadySigned.value = true;
            pdfMessage.value =
              'The chosen PDF file is already signed. Please select another PDF file.';
          } else {
            alreadySigned.value = false;
            pdfMessage.value = null;
          }

          const pdfBase64Data = await pdfDoc.value.saveAsBase64();
          pdfBase64.value = 'data:application/pdf;base64,' + pdfBase64Data;
          loading.value = false;
        };
        reader.onerror = (error) => {
          console.error('Error reading PDF:', error);
          pdfMessage.value = 'Error reading PDF. Please try again.';
          loading.value = false;
        };
        reader.readAsDataURL(pdfFile.value);
      } catch (error) {
        console.error('Error processing PDF:', error);
        pdfMessage.value = 'Error processing PDF. Please try again.';
        loading.value = false;
      }
    }

    return {
      pdfMessage,
      pdfHash,
      pdfBase64,
      pdfViewer,
      originalPdfBase64,
      firstName,
      lastName,
      email,
      signers,
      emailIsASigner,
      errorMessage,
      createSession,
      onPdfInput,
      addSigner,
      removeSigner,
      alreadySigned,
      pdfName,
      loading,
      loadingMessage,
    };
  },
};
</script>
