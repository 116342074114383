<template>
  <div
    class="py-5 px-5 lg:px-16 flex flex-col items-center justify-center bg-white"
  >
    <div
      class="container mx-auto px-4 md:py-10 flex flex-col md:flex-row items-start justify-center"
      v-if="currentEmail && !sessionExpired && !loading"
    >
      <div v-if="!loading" class="w-full md:w-1/2 container items-center">
        <div
          v-if="originalPdfBase64"
          class="flex justify-center items-center lg:justify-end lg:items-end"
        >
          <pdf-viewer
            :pdfBase64="originalPdfBase64"
            :pdfName="pdfName"
            @download="downloadOriginalPDF"
          />
        </div>
        <p
          v-if="pdfErrorMessage"
          class="text-xs text-center text-red-700 italic mt-1"
        >
          {{ pdfErrorMessage }}
        </p>
        <div
          v-if="pdfHash && signRequested"
          class="z-10 fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center"
        >
          <div
            class="bg-white md:w-2/3 md:h-2/3 mx-3 flex flex-col justify-center items-center rounded-lg shadow-md px-8 py-6 relative"
          >
            <p class="text-center text-[18px] mb-2">
              Provide the verifiable credential for this address:
            </p>
            <p class="text-center text-lg text-gray-700 font-bold mb-4">
              {{ currentEmail }}
            </p>
            <user-signin
              :customHash="pdfHash"
              @vpReceived="onVPReceived"
              :currentEmail="currentEmail"
              :sessionId="sessionId"
            ></user-signin>
            <button
              @click="signRequested = false"
              class="absolute top-2 right-2 text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="!loading"
        class="w-full md:w-1/2 mx-auto px-0 md:px-5 flex flex-col items-start"
      >
        <div
          class="w-full flex flex-col items-center md:items-start justify-center mb-3 sm:mb-5 px-0 md:px-5"
        >
          <div class="flex w-full items-center gap-2 mb-3 mt-5 md:mt-0 sm:mb-5">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-6 sm:w-9"
            />
            <h2 class="text-xl font-medium text-black">Web3 e-signatures</h2>
          </div>
          <p class="text-lg w-full font-light text-black lg:text-start">
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>

        <div class="w-full py-4 flex flex-col justify-center items-center">
          <div
            v-for="(signer, index) in signersEmails.slice().reverse()"
            :key="index"
            class="w-full flex flex-col justify-center items-center"
          >
            <div
              v-if="
                signer.email === sessionEmail ||
                (!signersEmails.find((email) => email == sessionEmail) &&
                  index == 0)
              "
              class="flex flex-col sm:flex-row w-full justify-center items-center gap-4 sm:gap-2"
            >
              <div
                class="flex-shrink-0 w-full sm:w-2/5 text-center sm:text-end"
              >
                <p class="text-black text-base font-sans">
                  {{ sessionDate.split('.')[0] }}
                  <br />{{ sessionDate.split('.')[1] }}
                </p>
              </div>
              <div
                class="flex justify-center items-center text-white w-full sm:w-1/5"
              >
                <div
                  class="bg-[#DEE0FF] flex justify-center items-center w-16 h-16 sm:w-[85px] sm:h-[85px] rounded-full"
                >
                  <img
                    :src="require('/assets/images/send_black.png')"
                    alt="illustration"
                    class="w-8 h-8 sm:w-[42px] sm:h-[37.69px]"
                  />
                </div>
              </div>
              <div
                class="flex-grow w-full sm:w-2/5 space-y-1 text-center sm:text-start"
              >
                <p class="text-base font-sans">
                  {{ sessionFirstname }} {{ sessionLastname }}
                </p>
                <p class="text-sm text-gray-700">
                  {{ sessionEmail }}
                </p>
                <p class="text-lg font-medium text-black">Sent</p>
              </div>
            </div>
            <div class="w-[0.5px] h-20 bg-gray-300 ml-2"></div>
            <div
              class="flex flex-col sm:flex-row w-full justify-center items-center gap-4 sm:gap-2"
            >
              <div
                class="flex-shrink-0 w-full sm:w-2/5 text-center sm:text-end"
              >
                <p class="text-black text-base font-sans">
                  {{ signer.statusDate.split('.')[0] }}
                  <br />{{ signer.statusDate.split('.')[1] }}
                </p>
              </div>
              <div
                class="flex justify-center items-center text-white w-full sm:w-1/5"
              >
                <div
                  :class="[
                    'flex justify-center items-center w-16 h-16 sm:w-[85px] sm:h-[85px] rounded-full',
                    !signer.vp
                      ? 'bg-[#FEF2DB]'
                      : signer.status === 'Signed'
                      ? 'bg-[#ADEFFF]'
                      : '',
                  ]"
                >
                  <img
                    :src="
                      require(!signer.vp
                        ? '/assets/images/signed_icon.png'
                        : '/assets/images/Circle_Check.png')
                    "
                    alt="illustration"
                    class="w-8 h-8 sm:w-[42px] sm:h-[42px]"
                  />
                </div>
              </div>
              <div
                class="flex-grow w-full sm:w-2/5 space-y-1 text-center sm:text-start"
              >
                <p class="text-base font-sans">
                  {{ signer.firstName }} {{ signer.lastName }}
                </p>
                <p class="text-sm text-gray-700">
                  {{ signer.email }}
                </p>
                <p class="text-lg font-medium text-black">
                  {{ !signer.vp ? 'Waiting for sign' : 'Signed' }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="!missingSignatures"
            @click="downloadPDF"
            class="w-full sm:w-[429.3px] h-12 sm:h-[80px] mb-4 flex justify-center items-center bg-primaryGradient gap-2 outline-none rounded-md border border-gray-300 cursor-pointer mt-16"
          >
            <img
              :src="require('/assets/images/secrure_white.png')"
              alt="illustration"
              class="w-8 sm:w-[40px]"
            />
            <p class="text-center text-lg text-white font-bold">Download</p>
          </div>
          <div v-else>
            <div class="mt-16 w-full flex justify-center py-2">
              <div
                v-if="
                  signersEmails.find(
                    (signer) => currentEmail === signer.email
                  ) &&
                  !signersEmails.find((signer) => currentEmail === signer.email)
                    .vp
                "
                @click="triggerSign"
                class="w-full sm:w-[429.3px] h-16 sm:h-[101px] flex justify-center items-center bg-primaryGradient gap-2 outline-none rounded-md border border-gray-300 cursor-pointer"
              >
                <img
                  :src="require('/assets/images/check_white.png')"
                  alt="illustration"
                  class="w-8 sm:w-[42px]"
                />
                <p class="text-white text-lg font-medium">Sign document</p>
              </div>
              <div
                v-else
                class="w-full sm:w-[429.3px] h-16 sm:h-[101px] flex justify-center items-center bg-black gap-2 outline-none rounded-md border border-gray-300"
              >
                <p class="text-white text-lg font-medium">Waiting Signatures</p>
              </div>
            </div>
            <p class="w-full sm:w-[429.3px] text-xs mt-5">
              This document is not signed by all requested signers yet. You will
              receive an email once it's done.
            </p>
          </div>
        </div>
        <p
          v-if="signErrorMessage"
          class="text-xs text-center text-red-700 italic mt-1"
        >
          {{ signErrorMessage }}
        </p>
      </div>
    </div>
    <div
      v-else-if="!currentEmail && !loading"
      class="bg-tertiary w-full sm:w-11/12 md:w-1/2 shadow-lg rounded-lg mx-auto p-4 sm:p-10 mt-2 sm:mt-5 mb-4 sm:mb-10"
    >
      <p class="text-center">Missing or bad email in query</p>
    </div>
    <div
      v-else-if="sessionExpired && !loading"
      class="bg-tertiary w-full sm:w-11/12 md:w-1/2 shadow-lg rounded-lg mx-auto p-4 sm:p-10 mt-2 sm:mt-5 mb-4 sm:mb-10"
    >
      <p class="text-center mb-5">Sorry, this session is expired.</p>
      <router-link to="'/'">
        <p class="text-center font-bold">
          <span class="border rounded-md shadow-sm px-2 py-1">Go to home</span>
        </p></router-link
      >
    </div>
    <div v-if="loading" class="px-4">
      <div class="flex justify-center mb-5">
        <custom-loader class="" color="#D0C4E7" size="50px"></custom-loader>
      </div>
      <p class="text-secondary text-center mb-3">Please wait...</p>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed } from 'vue';
import { useRoute } from 'vue-router';
import { PDFDocument } from 'pdf-lib';
import createKeccakHash from 'keccak';
import { toChecksumAddress } from 'ethereum-checksum-address';
import { base58btc } from 'multiformats/bases/base58';
import secp256k1 from 'secp256k1';
import { saveAs } from 'file-saver';

export default {
  setup() {
    const api = inject('api');
    const route = useRoute();
    const sessionId = route.params.id;
    let currentEmail = ref(route.query.email);

    const pdfBase64 = ref(null);
    const originalPdfBase64 = ref(null);
    const pdfHash = ref(null);
    const pdfName = ref(null);
    const pdfDoc = ref(null);
    const pdfSignedBase64 = ref(null);
    const signRequested = ref(false);
    const signErrorMessage = ref(null);
    const pdfErrorMessage = ref(null);

    const sessionEmail = ref(null);
    const sessionFirstname = ref(null);
    const sessionLastname = ref(null);
    const sessionDate = ref(null);
    const created = ref(null);
    const signersEmails = ref(null);
    const missingSignatures = ref(false);
    const sessionExpired = ref(false);
    const loading = ref(false);

    // Computed property for truncated pdf name
    const truncatedPdfName = computed(() => {
      if (pdfName.value) {
        return pdfName.value.length > 50
          ? pdfName.value.slice(0, 10) + '...' + pdfName.value.slice(-10)
          : pdfName.value;
      }
      return '';
    });

    async function getSession() {
      loading.value = true;
      pdfErrorMessage.value = null;

      const session = (await api.getSession(sessionId)).data;

      if (session.status && session.status == 'expired') {
        sessionExpired.value = true;
        loading.value = false;
        return;
      }

      console.log(session);

      pdfBase64.value = session.pdfData;
      originalPdfBase64.value = session.originalPdfData;
      pdfHash.value = session.pdfHash;
      pdfName.value = session.pdfName;
      sessionEmail.value = session.email;
      sessionFirstname.value = session.firstName;
      sessionLastname.value = session.lastName;
      signersEmails.value = session.signers.map((signer) => {
        return {
          firstName: signer.firstName,
          email: signer.email,
          lastName: signer.lastName,
          status:
            signer.vp && signer.signature
              ? 'Signed'
              : signer.email === currentEmail.value
              ? 'Waiting'
              : 'Sent',
          statusDate: formatDate(signer.statusDate) || formatDate(new Date()),
          vp: signer.vp,
          signature: signer.signature,
        };
      });
      sessionDate.value = formatDate(session.created) || formatDate(new Date());
      created.value = session.created;
      missingSignatures.value = !signersEmails.value.every(
        (signer) => signer.vp && signer.signature
      );

      if (
        currentEmail.value != sessionEmail.value &&
        !signersEmails.value.some((el) => el.email === currentEmail.value)
      ) {
        currentEmail.value = null;
      }
      loading.value = false;
    }

    getSession();

    async function triggerSign() {
      signErrorMessage.value = null;
      signRequested.value = true;
    }

    async function onVPReceived(vp) {
      loading.value = true;
      signRequested.value = false;
      if (
        didToAddress(vp.verifiableCredential[0].issuer) !=
        process.env.VUE_APP_VALID_EMAIL_ISSUER
      ) {
        signErrorMessage.value = 'Email credential not provided by myDid';
        loading.value = false;
        return;
      }

      if (
        vp.verifiableCredential[0].credentialSubject.email.toLowerCase() !=
        currentEmail.value.toLowerCase()
      ) {
        signErrorMessage.value = 'Email not corresponding';
        loading.value = false;
        return;
      }

      try {
        await api.updateSessionSigner({
          sessionId,
          signer: {
            email: currentEmail.value,
            vp,
          },
        });
        getSession();
      } catch (error) {
        signErrorMessage.value = 'Error updating session signer';
      } finally {
        loading.value = false;
      }
    }

    function formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return new Date(date)
        .toLocaleDateString('fr-FR', options)
        .replace('à', '. ');
    }

    async function downloadPDF() {
      try {
        pdfDoc.value = await PDFDocument.load(
          pdfBase64.value.split('base64,')[1]
        );

        const documentMetadata = {
          documentMetadata: {
            context: 'Mydid Signed Document',
            pdfHash: pdfHash.value,
            createdDate: created.value,
            verifications: signersEmails.value.map((signer) => ({
              firstName: signer.firstName,
              lastName: signer.lastName,
              verifiablePresentation: signer.vp,
              signatureBase64: signer.signature,
            })),
          },
        };

        const metadataString = encodeURIComponent(
          JSON.stringify(documentMetadata)
        );
        pdfDoc.value.setKeywords([metadataString]);

        pdfSignedBase64.value = await pdfDoc.value.saveAsBase64();

        // using file server to download the file
        const byteCharacters = atob(pdfSignedBase64.value);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const fileName = `${
          pdfName.value.split('.pdf')[0].split('.PDF')[0]
        }_signed.pdf`;
        saveAs(blob, fileName);
      } catch (error) {
        console.error('Error downloading PDF:', error);
        pdfErrorMessage.value = 'Error downloading PDF';
      }
    }

    function downloadOriginalPDF() {
      //use file server to download the file
      const byteCharacters = atob(originalPdfBase64.value.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const fileName = `${
        pdfName.value.split('.pdf')[0].split('.PDF')[0]
      }_original.pdf`;
      saveAs(blob, fileName);
    }

    function didToAddress(did) {
      const didValue = did.split(':')[2];
      try {
        const publicKeyUintArray = base58btc.decode(didValue);
        const compressedPublicKey =
          Buffer.from(publicKeyUintArray).toString('hex');
        const decompressedBuffer = secp256k1.publicKeyConvert(
          Buffer.from(compressedPublicKey, 'hex'),
          false
        );
        const hash = createKeccakHash('keccak256')
          .update(Buffer.from(decompressedBuffer).slice(1))
          .digest();
        return toChecksumAddress(hash.slice(-20).toString('hex'));
      } catch (e) {
        return didValue;
      }
    }

    return {
      pdfHash,
      pdfBase64,
      originalPdfBase64,
      pdfSignedBase64,
      sessionEmail,
      sessionFirstname,
      sessionLastname,
      signersEmails,
      sessionDate,
      signRequested,
      signErrorMessage,
      pdfErrorMessage,
      missingSignatures,
      sessionExpired,
      currentEmail,
      downloadPDF,
      downloadOriginalPDF,
      onVPReceived,
      triggerSign,
      pdfName,
      truncatedPdfName,
      sessionId,
      loading,
    };
  },
};
</script>
