<template>
  <div
    class="py-5 px-5 lg:px-16 flex flex-col items-center justify-center bg-white"
  >
    <div
      class="container mx-auto px-4 md:py-10 flex flex-col md:flex-row items-start justify-center"
    >
      <div
        v-if="!pdfBase64"
        class="w-full hidden sm:block md:w-1/2 lg:w-1/3 gap-2 mb-10 md:mb-0 md:mr-10"
      >
        <img
          :src="require('/assets/images/signer_img.png')"
          alt="e-signature illustration"
          class="w-[350px] h-auto md:w-[500px] lg:w-[600px] 2xl:w-[700px] mx-auto"
        />
      </div>

      <div v-else class="w-full md:w-1/2 gap-2 mb-10 md:mb-0 md:mr-10">
        <div class="w-full flex flex-col justify-end items-end">
          <pdf-viewer
            :pdfBase64="pdfBase64"
            :pdfName="pdfName"
            @download="downloadOriginalPDF"
          />
        </div>
      </div>
      <div
        class="w-full md:w-1/2 lg:w-1/2 flex flex-col items-center lg:items-start"
      >
        <!-- Title and Description -->
        <div class="my-7 w-full">
          <div class="flex items-center my-5 gap-2">
            <img
              :src="require('/assets/images/warning.png')"
              alt="illustration"
              class="w-5 sm:w-6 lg:w-8"
            />
            <h2 v-if="!pdfStatus" class="text-xl font-medium text-black">
              Web3 e-signatures
            </h2>
            <h2
              v-else-if="pdfStatus == 'fail'"
              class="text-xl font-medium text-black"
            >
              Document Verification failed
            </h2>
            <h2
              v-else-if="pdfStatus == 'success'"
              class="text-xl font-medium text-black"
            >
              Document verified
            </h2>
          </div>
          <p class="text-lg max-w-xl text-start font-light text-black">
            This demo allows to sign and verify PDF document using myDid mobile
            application.
          </p>
        </div>

        <!-- Upload Section -->
        <div class="flex flex-col gap-4 w-full">
          <div
            v-if="pdfStatus == 'fail'"
            class="w-full flex flex-col justify-start items-start"
          >
            <p class="text-start font-medium text-[#4131C6] mb-2">
              Your document cannot be verified.
            </p>
            <p class="text-start font-medium mb-1">Reason:</p>
            <p class="text-start mb-5">
              <span class="text-2xl max-w-xl mx-1 text-[#4131C6]"> • </span
              >{{ pdfErrorMessage }}
            </p>
          </div>

          <button
            v-if="!pdfStatus"
            @click="$refs.pdfToVerifyInput.click()"
            class="w-full max-w-[412px] space-x-2 justify-center items-center border border-gray-300 flex-row flex px-6 py-4 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
          >
            <img
              :src="require('/assets/images/add.png')"
              alt="plus"
              class="w-5"
            />
            <span class="">Upload PDF to verify</span>
          </button>

          <button
            v-if="pdfStatus == 'fail'"
            @click="$router.push('/sign')"
            class="w-full max-w-[412px] space-x-2 justify-center items-center border border-gray-300 flex-row flex px-6 py-4 bg-white text-black font-medium rounded-lg shadow-sm hover:bg-gray-50 transition-all"
          >
            <img
              :src="require('/assets/images/warning.png')"
              alt="warning"
              class="w-5"
            />
            <span class="">Sign PDF</span>
          </button>

          <input
            type="file"
            @change="onPdfInput($event)"
            ref="pdfToVerifyInput"
            class="hidden"
            accept="application/pdf"
          />

          <div
            v-if="pdfStatus == 'success'"
            class="mt-5 w-full flex flex-col justify-start items-start"
          >
            <p class="text-start font-medium text-lg mb-2">
              Your document was successfully verified.
            </p>
            <p v-if="signDate" class="text-start font-medium text-xs mb-2">
              Signature process completed at :
              <span class="font-normal">{{ signDate }}</span>
            </p>
            <p class="text-start font-medium text-xs mb-3">Participants:</p>
            <div v-for="(signer, index) in signers" :key="index" class="mb-3">
              <div class="relative bg-white">
                <img
                  :src="require('/assets/images/sign_template.png')"
                  alt="sign template"
                  class="lg:w-[65%]"
                />
                <div
                  class="absolute top-1/2 left-1/2 md:left-1/3 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center text-center text-xs"
                >
                  <p
                    class="flex items-center justify-center text-center text-xs"
                  >
                    {{ signer.firstName }} {{ signer.lastName }}
                  </p>
                  <p
                    class="flex items-center justify-center text-center text-xs"
                  >
                    {{ formatDate(signer.created) }}
                  </p>
                  <p
                    class="flex items-center justify-center text-center text-xs"
                  >
                    {{ signer.email }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { PDFDocument } from 'pdf-lib';
import crypto from 'crypto';

export default {
  setup() {
    const utils = inject('utils');
    const pdfFile = ref(null);
    const pdfStatus = ref(null);
    const pdfHash = ref(null);
    const pdfErrorMessage = ref(null);
    const verifiablePresentations = ref([]);
    const pdfName = ref(null);
    const pdfBase64 = ref(null);
    const signers = ref([]);
    const signDate = ref(null);

    async function onPdfInput(event) {
      pdfFile.value = null;
      pdfStatus.value = null;
      pdfErrorMessage.value = null;
      pdfHash.value = null;
      verifiablePresentations.value = [];

      pdfFile.value = event.target.files[0];
      pdfName.value = event.target.files[0].name;

      const reader = new FileReader();
      reader.readAsDataURL(pdfFile.value);

      reader.onload = async (e) => {
        const pdfData = e.target.result.split('base64,')[1];
        const pdfDoc = await PDFDocument.load(pdfData);
        const pdfMetadata = utils.getMetadataFromPdfDoc(pdfDoc);

        pdfDoc.setModificationDate(new Date(0));
        utils.resetPdfHeaders(pdfDoc);
        const modifiedPdfData = await pdfDoc.saveAsBase64();
        pdfBase64.value = 'data:application/pdf;base64,' + modifiedPdfData;
        pdfHash.value = crypto
          .createHash('sha256')
          .update(modifiedPdfData)
          .digest('hex');

        if (pdfMetadata && pdfMetadata.createdDate)
          signDate.value = formatDate(pdfMetadata.createdDate) || null;

        if (pdfMetadata && pdfMetadata.verifications.length > 0)
          pdfMetadata.verifications.forEach((verification) => {
            verifiablePresentations.value.push(
              verification.verifiablePresentation
            );
            signers.value = pdfMetadata.verifications.map((verification) => {
              return {
                email:
                  verification.verifiablePresentation.verifiableCredential[0]
                    .credentialSubject.email,
                created: verification.verifiablePresentation.proof.created,
                firstName: verification.firstName,
                lastName: verification.lastName,
              };
            });
          });

        if (!pdfHash.value || verifiablePresentations.value.length === 0) {
          pdfErrorMessage.value =
            'The chosen PDF is not signed. To sign it, please go to the sign section.';
          pdfStatus.value = 'fail';
          return;
        }

        for (let vp of verifiablePresentations.value) {
          const pdfHashFromVP = vp.proof.challenge.split('-')[1];
          if (pdfHashFromVP !== pdfHash.value) {
            pdfErrorMessage.value =
              'The signature on the chosen PDF is not valid. Please select another PDF file.';
            pdfStatus.value = 'fail';
            return;
          }
        }

        pdfStatus.value = 'success';
      };
    }

    function downloadOriginalPDF() {
      const byteCharacters = atob(pdfBase64.value.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const fileName = `${
        pdfName.value.split('.pdf')[0].split('.PDF')[0]
      }_original.pdf`;
      saveAs(blob, fileName);
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      return (
        date.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
          timeZone: 'UTC',
        }) + ' UTC'
      );
    }

    return {
      pdfStatus,
      pdfErrorMessage,
      onPdfInput,
      pdfBase64,
      pdfName,
      downloadOriginalPDF,
      signDate,
      signers,
      formatDate,
    };
  },
};
</script>
