<template>
  <header class="w-full bg-white p-3">
    <div
      class="container mx-auto px-4 py-6 flex flex-col md:flex-row items-center justify-between"
    >
      <router-link to="/">
        <div class="flex items-center mb-4 md:mb-0">
          <img
            :src="require('/assets/images/logo.png')"
            alt="myDid Sign"
            class="h-12 md:h-20 lg:h-[50px] xl:h-[80px] w-auto mr-3"
          />
          <img
            :src="require('/assets/images/sign.png')"
            alt="myDid Sign"
            class="h-12 md:h-20 lg:h-[80.4px] w-auto mr-3"
          />
        </div>
      </router-link>
      <div class="flex items-center space-x-4">
        <a
          href="https://support.mydid.com/fr/"
          target="_blank"
          class="flex items-center mr-6"
        >
          <img
            :src="require('/assets/images/helpe.png')"
            alt="help"
            class="h-4 md:h-8 lg:h-[28.13px] w-auto"
          />
          <span class="text-gray-700 text-sm ml-2"
            >Aide</span
          >
        </a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  setup() {},
};
</script>
